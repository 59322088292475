import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { aOrganization } from '~/atoms'
import type { DataLayerEntry, ModelFind } from '~/models'
import type { ApiResponse } from './utils'

type FetchDataLayerEntriesResponse = ApiResponse & {
  dataLayerEntries: DataLayerEntry[]
  count: number
  nextOffset: number
}

async function fetchDataLayerEntries({
  find,
  sort,
  project,
  limit,
  offset,
  signal,
}: {
  find: ModelFind
  sort?: { [key: string]: number }
  project?: {}
  limit?: number
  offset?: number
  signal?: AbortSignal
}): Promise<FetchDataLayerEntriesResponse> {
  const response = await fetch('/api/data-layer-entries', {
    body: JSON.stringify({
      find,
      sort,
      project,
      limit,
      offset,
    }),
    method: 'POST',
    signal,
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading data layer entries', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useDataLayerEntriesQuery({
  key,
  find,
  sort,
  keepPreviousData = true,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  refetchInterval,
  enabled,
}: {
  key: string[]
  find?: { [key: string]: any }
  sort?: { [key: string]: number }
  keepPreviousData?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
  refetchInterval?: number
  enabled?: boolean
}) {
  const organization = useAtomValue(aOrganization)
  const queryClient = useQueryClient()
  const dataLayerEntriesQuery = useQuery({
    queryKey: key,
    queryFn: !find
      ? undefined
      : () =>
          fetchDataLayerEntries({
            find: { ...find, 'organization._id': organization!._id },
            sort,
          }),
    // keepPreviousData,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchInterval,
    enabled: enabled && !!find,
  })
  return {
    isLoading:
      dataLayerEntriesQuery.isLoading ||
      dataLayerEntriesQuery.isFetching ||
      dataLayerEntriesQuery.isRefetching ||
      dataLayerEntriesQuery.isPending,
    isError: dataLayerEntriesQuery.isError,
    dataLayerEntries: dataLayerEntriesQuery.data?.dataLayerEntries || [],
    refetch: dataLayerEntriesQuery.refetch,
    updateDataLayerEntriesData: (
      updateFunc: (
        previousDataLayersEntries: DataLayerEntry[]
      ) => DataLayerEntry[]
    ) => {
      return queryClient.setQueryData(
        key,
        (previousData?: {
          success: boolean
          dataLayerEntries: DataLayerEntry[]
        }) => {
          if (!previousData) return undefined
          return {
            ...previousData,
            dataLayerEntries: updateFunc(previousData.dataLayerEntries),
          }
        }
      )
    },
  }
}
